.body-contain {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 90vw;
    text-align: center;
}

.container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
}

.container h2 {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
}

.container p {
    text-align: justify;        
    font-size: 16px;
    color: #333333;
    margin-top: 10px;
}