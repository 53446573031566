.body-contain {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}


.container {
    width: 100%;
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h2 {
    text-align: center;
    color: #333;
}
.register-terms {
    text-align: left;
    display: inline-block;    
    font-size: small;
}
.register-terms label {    
    text-align: left;    
    font-size: small;
}
.register-terms  a {
    color: blue;
    text-decoration: underline;
    
}

form {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: rgb(246, 246, 246);
    margin-top: 20px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.register-button{
    padding: 10px;
    font-size: x-large;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;    
}

button:hover {
    background-color: #218838;
}

button:disabled {
    background-color: #606461;
}
.error {
    color: red;
    margin-top: 10px;
}

.pwd-conditions{
    color: red;    
    font-size:small;
    margin-bottom: 20px;   
    width: 50vw;
}