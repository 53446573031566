.body-contain {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}


.container {
    width: 100%;
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h2 {
    text-align: center;
    color: #333;
}
.register-terms {
    margin: 30px;
    width: 50vw;
    text-align: center;
    display: inline-block;
    font-size: small;
}

form {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: rgb(246, 246, 246);
    margin-top: 20px;
}

input[type="text"],
input[type="email"],
input[type="number"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;    
}

.verification-code{
    font-size: 22px;
    letter-spacing: 20px;
}


.register-button{
    padding: 10px;
    font-size: x-large;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;    
}


.resend-button{
    padding: 5px;
    font-size: large;
    margin-top: 20px;
    background-color: #191ce9;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;    
}
.info-message{
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: #bcaa09;
    width: 50vw;
}


button:hover {
    background-color: #218838;
}

.error {
    color: red;
    margin-top: 10px;
}