.testimonial-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
}
 
.testimonial-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
 
.testimonial-text11 {
  text-align: center;
}
 
.testimonial-container12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial-container13 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial-text12 {
  text-align: left;
}
 
.testimonial-container14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial-container15 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial-text13 {
  text-align: left;
}
 
.testimonial-container16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial-container17 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial-text14 {
  text-align: left;
}
 
.testimonial-container18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.testimonial-container19 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.testimonial-text15 {
  text-align: left;
}
 
.testimonial-text16 {
  display: inline-block;
}
 
.testimonial-text17 {
  display: inline-block;
}
 
.testimonial-text18 {
  display: inline-block;
}
 
.testimonial-text19 {
  display: inline-block;
}
 
@media(max-width: 991px) {
  .testimonial-container10 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
 
@media(max-width: 767px) {
  .testimonial-container10 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .testimonial-card1 {
    width: 100%;
  }
  .testimonial-card2 {
    width: 100%;
  }
  .testimonial-card3 {
    width: 100%;
  }
  .testimonial-card4 {
    width: 100%;
  }
}
