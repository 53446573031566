.body-contain {
    width: 100%;
    display: flex;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.login-container {
    width: 100%;
    display: flex;    
    align-items: center;
    flex-direction: column;
}

.login-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
}

.login-container input[type="text"],
.login-container input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #cccccc;
    border-radius: 4px;
}

.login-container button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
}

.login-container button:hover {
    background-color: #0056b3;
}

.login-container .error{
    color: red;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;   
}