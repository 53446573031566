.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.home-text100 {
  display: inline-block;
}
 
.home-text105 {
  display: inline-block;
}
 
.home-text121 {
  display: inline-block;
}
 
.home-text133 {
  display: inline-block;
}
 
.home-text145 {
  display: inline-block;
}
 
.home-text155 {
  display: inline-block;
}
 
.home-text167 {
  display: inline-block;
}
 
.home-text177 {
  display: inline-block;
}
 
.home-text189 {
  display: inline-block;
}
 
.home-text190 {
  display: inline-block;
}
 
.home-text194 {
  display: inline-block;
}
 
.home-text200 {
  display: inline-block;
}
 
.home-text204 {
  display: inline-block;
}

.home-list {
  margin-top: 20px;
  text-align: left;
}
